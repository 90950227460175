import React, { HTMLAttributes } from 'react';
import Header from './Header/header';
import Hero from './Hero/hero';
import './normalize.css';
import Projects  from './Projects/projects';
import SEO from './SEO/seo';

interface LayoutProps extends HTMLAttributes<HTMLElement>{
  noheader?: boolean;
  notopmargin?: boolean;
  nobottommargin?: boolean;
  mainpage?: boolean;
  backto?: string;
  fullheight?: boolean;
}

const Layout = ({ children, noheader, notopmargin, mainpage, backto, nobottommargin, fullheight }: LayoutProps) => (
  <div style={{height: fullheight ? '100%' : 'auto'}} className={notopmargin ? 'Layout Layout_notopmargin' : 'Layout'}>
          {!noheader &&  <Header backTo={backto} languages={mainpage}/>}
          {children}
  </div>
);

export default Layout;

import React, { useContext } from 'react';
import { Context } from '../../Store/store';
import './menu.css';

interface MenuProps {
  type?: 'wt' | "bl";
}

const Menu = ({ type }: MenuProps) => {
  const { state } = useContext(Context);
  const label = state.changeLanguage === 'RU' ? 'ЭЛЕКТРОПОЧТА' : 'MAILTO';

  return (
    <a target="_blank" rel="noopener noreferrer" href="mailto:contact@artyomalekseev.com?subject=Website" className={type === 'wt' ? 'Header-menu Header-menu_wt' : 'Header-menu'}>
        {label}
    </a >
  ); };

export default Menu;

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import SEOProps from './seo';

interface SEOProps {
  description: string;
  lang: string;
  meta?: [];
  keywords: string[];
  title: string;
  hreflangurl: string;
  hreflang: 'ru' | 'en';
}

const SEO = ({ description, lang, meta, keywords, title, hreflangurl, hreflang, children }:SEOProps) => {
  const { site, coverImage } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            twitterUsername
            siteVerification {
              google
            }
          }
        }
        coverImage: file(relativePath: { eq: "socialCover.png" }) {
          childImageSharp {
            resize(width: 1200) {
              src
              height
              width
          }
        }
      }
    }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;
  const image = `${site.siteMetadata.siteUrl}${coverImage.childImageSharp.resize.src}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          name: 'google-site-verification',
          content: site.siteMetadata.siteVerification.google,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.twitterUsername,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:image:width',
          content: coverImage.childImageSharp.resize.width,
        },
        {
          property: 'og:image:height',
          content: coverImage.childImageSharp.resize.height,
        },
        {
          name: 'twitter:card',
          content: 'summary_large_image',
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
            : [],
        )
        .concat(meta)}
    >
      {children}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
  twitterUsername: '',
  description: '',
};

export default SEO;

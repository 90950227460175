import * as React from 'react';
import './social.css';
import SocialContainer from './SocialContainer/socialContainer';

interface SocialProps {
  type?: 'wt' | 'bl';
}

const Social = ({ type }: SocialProps) => (
    <div className={type === "wt" ? 'Social Social_wt' : 'Social'}>
        <SocialContainer />
    </div>
);

export default Social;

import * as React from 'react';
import './socialItem.css';

interface SocialItemProps {
  name: string;
  url: string;
}

const SocialItem: React.FunctionComponent<SocialItemProps> = ({ name, url }) => (
    <a target="_blank" rel="noopener noreferrer" href={url} className="Social-item">{name}</a>
);

export default SocialItem;
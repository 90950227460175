import { Link } from 'gatsby';
import React from 'react';
import './arrow.css';

interface ArrowInterface {
  toRU?: boolean;
  type?: 'wt' | 'bl';
  backTo?: string;
}

const Arrow = ({ toRU, type, backTo }: ArrowInterface) => {
  const url = toRU ? '/ru/design' : '/design';

  return (
    <Link to={backTo ? url + backTo : url}>
        {type === 'wt' ? <ArrowSVG_wt/> : <ArrowSVG/>}
    </Link>
  );
};

export default Arrow;

const ArrowSVG = () => (<svg width="33" height="19" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M2 9.5H33M2 9.5L10 1.5M2 9.5L10 17.5" stroke="#222222" strokeWidth="2"/>
 </svg>);

const ArrowSVG_wt = () => (<svg width="33" height="19" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
 <path d="M2 9.5H33M2 9.5L10 1.5M2 9.5L10 17.5" stroke="#ffffff" strokeWidth="2"/>
 </svg>);

import * as React from 'react';
import { useContext } from 'react';
import { Context } from '../../Store/store';
import './socialContainer.css';
import SocialItem from './SocialItem/socialItem';

const socials = [
  { id: 0, name: 'Dribbble', nameRU: 'Дрибл', url:  'https://dribbble.com/artyomalekseev' },
    { id: 1, name: 'Mailto', nameRU: 'электропочта', url:  'mailto:contact@artyomalekseev.com?subject=Website' },
    { id: 2, name: 'Github', nameRU: 'Гитхаб', url:  'https://github.com/rexolion' },
];

const SocialContainer = () => {
  const { state } = useContext(Context);
  const lang = state.changeLanguage;

  return (
    <div className="Social-container">
        {socials.map(val => <SocialItem url={val.url} name={lang === 'RU' ? val.nameRU.toUpperCase() : val.name.toUpperCase()} key={val.id}/>)}
    </div>
  ); };

export default SocialContainer;

import React, { useContext } from 'react';
import { Context } from '../../Store/store';
import './langs.css';
import { Link } from 'gatsby';

const Langs = () => {

  return (
    <div className="Header-langs">
        <div title="In development" className="Header-langs-item"
            >Artyom Alekseev</div>
    </div>
  );
};

export default Langs;

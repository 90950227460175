import React from 'react';
import Social from '../Social/social';
import Arrow from './Arrow/arrow';
import './header.css';
import Langs from './Langs/langs';
import Menu from './Menu/menu';

interface HeaderInterface {
  languages?: boolean;
  toRU?: boolean;
  backTo?: string;
  type?:'wt' | 'bl';
}

const Header = ({ languages, toRU, type, backTo }: HeaderInterface) => (
  <header className='Header-container'>
    <div className='Header-backdrop'></div>
    <div className={type === 'wt' ? 'Header Header_wt' : 'Header'}>
        {languages ? <Langs/>
        : <Arrow backTo={backTo} type={type} toRU={toRU}/>}
        <Social type={type}/>
        <Menu type={type}/>
    </div>
  </header>
);

export default Header;
